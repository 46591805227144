import React from "react"
import Title from "../components/Title"
import styled from "styled-components"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import StyledHero from "../components/StyledHero"
import "react-vertical-timeline-component/style.min.css"
import {
  FaDraftingCompass,
  FaChevronCircleUp,
  FaMagnet,
  FaNewspaper,
  FaHammer,
  FaMale,
  FaBuilding,
  FaCar,
} from "react-icons/fa"

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Firmengeschichte"
        description={data.site.siteMetadata.description}
      />

      <StyledHero img={data.geschichteImage.childImageSharp.fluid}></StyledHero>

      <ServicesContainer>
        <Title title="Unsere " subtitle="Firmengeschichte!" />
        <hr className="services_separator" />
        <TextWrapper>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="2000 bis heute"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaChevronCircleUp />}
            >
              <h3 className="vertical-timeline-element-title">
                Übernahme der Firma
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                durch Thomas Berenz
              </h4>
              <p>Mitarbeiterzahl 6 - 8</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="2000"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaMale />}
            >
              <h3 className="vertical-timeline-element-title">
                Ausscheiden von Paul Berenz
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                als Geschäftsführer und Firmenmitinhaber
              </h4>
              <p>und Antritt des wohlverdienten Ruhestandes.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="1995"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaNewspaper />}
            >
              <h3 className="vertical-timeline-element-title">
                Umfirmierung des Betriebes
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                in Fa. P. Berenz GmbH Bedachungen-Bauklempnerei
              </h4>
              <p>mit den Geschäftsführern Paul und Thomas Berenz.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="1989"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaMagnet />}
            >
              <h3 className="vertical-timeline-element-title">
                Eintritt von Sohn Thomas Berenz in den Betrieb
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                nach erfolgreichem Abschluss
              </h4>
              <p>
                eines Bauingenieurstudiums und der Meisterprüfung im
                Dachdeckerhandwerk.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="1983"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaBuilding />}
            >
              <h3 className="vertical-timeline-element-title">
                Neubau einer Betriebshalle
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                mit Büro in Cochem-Brauheck
              </h4>
              <p>und Verlagerung des Firmensitzes</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="1974-1977"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaDraftingCompass />}
            >
              <h3 className="vertical-timeline-element-title">
                Dachdeckerlehre
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                mit erfolgreichem Abschluss
              </h4>
              <p>Thomas Berenz</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="1971"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaCar />}
            >
              <h3 className="vertical-timeline-element-title">
                Verlagerung von Firmensitz und Wohnort
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                von Cochem-Cond nach Cochem, Kelbergerstraße
              </h4>
              <p>Vergrößerung der Mitarbeiterzahl auf 4-5</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="1963"
              iconStyle={{ background: "var(--primaryColor)", color: "#fff" }}
              icon={<FaHammer />}
            >
              <h3 className="vertical-timeline-element-title">
                Übernahme der Fa. Hans Hünerfeld in Cochem
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                durch Dachdeckermeister Paul Berenz
              </h4>
              <p>und Gründung der Fa. P. Berenz Bedachungen</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement></VerticalTimelineElement>
          </VerticalTimeline>
        </TextWrapper>
      </ServicesContainer>
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    geschichteImage: file(relativePath: { eq: "bgslider/DSC_3217.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const ServicesContainer = styled.section`
  padding-top: 3rem;

  .services_separator_margin {
    width: 20%;
    margin: 3rem auto;
  }

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;

  @media (min-width: 780px) {
    padding: 4rem;
    margin-top: 4rem;
  }
`

export default Index
